<template>
  <div>
    <!-- Section Path File/Modul -->
    <v-breadcrumbs
      :items="pathModul"
      divider="-"
      normal
      class="pathModul"
    ></v-breadcrumbs>

    <!-- Section Filtering Input/Select/Button -->
    <v-container class="container-filtering">
      <v-row align="center">
        <!-- Column Select Station -->
        <v-col class="col-input-filter">
          <v-select
            v-on:change="selectValueStation"
            :items="itemStation"
            label="Station"
          ></v-select>
        </v-col>

        <!-- Column Select Year -->
        <!-- <v-col
                class="col-input-filter col-dateYear"
                >
                    <v-icon>
                      mdi-calendar
                    </v-icon>
                    <v-select
                    v-on:change="selectValueDate"
                    :items="dropdownDate"
                    label="Date"
                    class="input-date-year"
                    >
                    </v-select>
                </v-col> -->

        <!-- Column Select Month -->
        <v-col class="col-input-filter">
          <v-menu
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
            v-model="modalCalendar"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateFormat"
                label="Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-on:change="selectDate"
              v-model="dateFormat"
              @input="modalCalendar = false"
              type="month"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <!-- Column Generate Button -->
        <v-col cols="12" sm="6" md="4" class="col-btn-generateReport">
          <v-btn
            class="ma-2 white--text btn-generate primary"
            solo
            dense
            normal
            @click="GenerateTable"
          >
            Generate Report
            <v-icon right dark big>
              mdi-table-arrow-up
            </v-icon>
          </v-btn>

          <!-- <v-btn
                    color="red"
                    elevation="2"
                    class="btn-download-table"
                    @click="exportReport('pdf')"
                    >
                      <v-icon
                      color="white"
                      center
                      dark
                      >
                          mdi-file-pdf-box
                      </v-icon>
                    </v-btn> -->

          <!-- <v-btn
                    color="green"
                    elevation="2"
                    @click="exportReport('excel')"
                    >
                      <v-icon
                      color="white"
                      center
                      dark
                      >
                          mdi-microsoft-excel
                      </v-icon>
                    </v-btn> -->
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col class="column-upload-button">
          <v-btn
            v-if="adminCanUse || operationCanUse || qaqcCanUse"
            class="ma-2 white--text btn-upload primary"
            solo
            dense
            normal
            @click="UploadForm"
          >
            Upload File
            <v-icon right dark big>
              mdi-file-undo-outline
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <!-- <v-btn normal>
                <v-icon right>
                mdi-trash-can-outline
                </v-icon>
            </v-btn> -->
    </v-container>

    <!-- Section Result Generated Report (TABLE) -->
    <v-container class="container-result-generated form-datatable">
      <!-- Mini Title (Table Title) -->
      <v-subheader class="subTitle fontSubTitle-blue">
        Maintenance Record For {{ stationID }} ({{ dateSelected }})
      </v-subheader>

      <!-- Table -->
      <v-data-table
        :headers="thead_maintenance"
        :items="tbody_maintenance"
        :header-props="{ sortIcon: null }"
        class="elevation-1 tableMaintenance headerDtSarawak"
        :items-per-page="5"
        no-select-on-click
        :custom-sort="customSort"
      >
        <template v-slot:[`item.datetimeUpload`]="{ item }">
          <!-- {{ item.datetime_a }} -->
          <span v-html="item.datetimeUpload"></span>
        </template>
        <template v-slot:[`item.fileDoc`]="{ item }">
          <v-icon
            color="#02b2c3"
            v-html="item.fileDoc"
            @click="openFileDoc(item)"
          >
          </v-icon>
        </template>
        <template v-slot:[`item.delDetails`]="{ item }">
          <v-icon
            :disabled="userCanUse"
            color="red"
            v-html="item.delDetails"
            @click="deleteItem(item)"
          >
          </v-icon>
        </template>
      </v-data-table>
    </v-container>

    <!-- Section Upload File (TABLE) -->
    <v-container class="container-upload-file form-uploadFile"> </v-container>
  </div>
</template>

<script>
import axios from "axios";
// import Papa from "papaparse";

export default {
  data: () => ({
    pathModul: [
      {
        text: "Home",
        disabled: false,
        href: "/",
        // href: '/Mapviewer',
      },
      {
        text: "Report - Maintenance Record",
        disabled: true,
        href: "/Operational/Maintenance",
      },
    ],
    itemStation: [],
    dateFormat: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 7),
    menu: false,
    modalCalendar: false,
    menu2: false,

    // Variable/Object for value filtering
    valStation: null,
    valDate: null,

    // Title - Subtitle After Generate Data
    stationID: null,
    dateSelected: null,
    monthText: [
      "Null",
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],

    // DataTable
    thead_maintenance: [
      {
        text: "Station ID",
        value: "stationId",
        divider: true,
        align: "center",
        sortable: false,
      },
      {
        text: "Date of Maintenance",
        value: "datetimeUpload",
        divider: true,
        align: "center",
      },
      { text: "Uploader", value: "uploader", divider: true, align: "center" },
      {
        text: "Document",
        value: "fileDoc",
        divider: true,
        align: "center",
        sortable: false,
      },
      // {text: "Document",value: "", divider: true, align: "center"},
      {
        text: "Delete",
        value: "delDetails",
        divider: true,
        align: "center",
        sortable: false,
      },
    ],
    tbody_maintenance: [],

    bodyDeleteBtn: { text: "Actions", value: "delDetails", sortable: false },
  }),

  methods: {
    convertDateFormat(date) {
      var dd = date.split("-")[2];
      var mm = date.split("-")[1];
      var yyyy = date.split("-")[0];
      date = dd + "/" + mm + "/" + yyyy;
      return date;
    },

    load() {
      this.getStation();
    },

    getStation() {
      axios
        .get("https://apis.spatialworks.com.my/sarawak/cms/stations", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {
          // console.log(response.data);
          this.itemStation = [];

          for (let i = 0; i < response.data.length; i++) {
            this.itemStation.push(
              response.data[i].stationId + " - " + response.data[i].location
            );
          }
          this.itemStation.push("ALL");
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // Get value from Selected Station
    selectValueStation: function(e) {
      // console.log(e);
      this.valStation = e;
    },

    // Get value from Selected Date
    selectDate: function(e) {
      this.valDate = e;
    },

    getDataMaintenance(station, year, month) {
      axios
        .get(
          "https://apis.spatialworks.com.my/sarawak/maint/data?stationid=" +
            station +
            "&year=" +
            year +
            "&month=" +
            month,
          {
            headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
            },
          }
        )
        .then((response) => {
          let displayData = [];

          for (let i = 0; i < response.data.length; i++) {
            response.data[i]["datetimeUpload"] = this.convertDateFormat(
              response.data[i]["datetimeUpload"]
            );
          }

          response.data.forEach(function(res) {
            // if (res["datetimeUpload"] != null) {
            //     res["datetimeUpload"] = res["datetimeUpload"].replaceAll('T', '<br>');
            // }

            res["fileDoc"] = "mdi-file-document";
            res["delDetails"] = "mdi-delete";
            displayData.push(res);
          });
          // displayData.shift(this.bodyDeleteBtn)
          this.tbody_maintenance = displayData;
          console.log(this.tbody_maintenance);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    openFileDoc: function(e) {
      console.log(e);
      // window.location.href = e.filepath;
      window.open(e.filepath, "_blank");
    },

    deleteItem: function(e) {
      console.log(e);
      axios
        .get("https://apis.spatialworks.com.my/sarawak/maint/del?id=" + e.id, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {
          console.log("Success Deleted");
          this.GenerateTable();
        })
        .catch((error) => {
          console.log(error);
          console.log("Unsuccess...");
        });
    },

    GenerateTable() {
      // Keluarkan Details SubText
      this.stationID = null;
      this.stationID = this.valStation.split(" - ")[0];
      for (let i = 0; i < this.monthText.length; i++) {
        if (i == this.dateFormat.split("-")[1]) {
          this.dateSelected =
            this.monthText[i] + " " + this.dateFormat.split("-")[0];
        }
      }

      // Unhide checkbox on header
      document.querySelector(".subTitle").style.display = "block";
      let chk = document.querySelectorAll(".checkbox-header-dailyReport");
      for (let i = 0; i < chk.length; i++) {
        chk[i].style.display = "contents";
      }

      this.getDataMaintenance(
        this.stationID,
        this.dateFormat.split("-")[0],
        this.dateFormat.split("-")[1]
      );
    },

    UploadForm: function() {
      window.location.href = "/Operational/MaintenanceUpload";
    },

    customSort: function(items, index, isDesc) {
      //   console.log(items, index, isDesc);

    //   console.log(index);

      items.sort((a, b) => {
        if (index[0] == "datetimeUpload") {
          //   console.log(new Date(this.standardDtFormatNuzul(b[index])));
          if (!isDesc[0]) {
            return (
              new Date(this.standardDtFormatNuzul(b[index])) -
              new Date(this.standardDtFormatNuzul(a[index]))
            );
          } else {
            return (
              new Date(this.standardDtFormatNuzul(a[index])) -
              new Date(this.standardDtFormatNuzul(b[index]))
            );
          }
        } else {
          if (typeof a[index] !== "undefined") {
            if (!isDesc[0]) {
              return a[index]
                .toLowerCase()
                .localeCompare(b[index].toLowerCase());
            } else {
              return b[index]
                .toLowerCase()
                .localeCompare(a[index].toLowerCase());
            }
          }
        }
      });
      return items;
    },
  },

  mounted() {
    this.load();
  },
};
</script>

<style lang="scss">
@import "~scss/main";

/* Section Path File/Modul */
.pathModul {
  padding-bottom: 0px;
}

.pathModul > li:nth-child(3) > a,
.pathModul > li:nth-child(3) > div {
  color: black !important;
}

/* Section Filtering Input/Select/Button */
.container-filtering {
  padding: 0px;
}

.col-dateYear {
  display: flex !important;
}

.col-dateYear > i {
  margin-right: 9px !important;
}

.btn-download-table {
  margin-right: 8px;
}

.column-upload-button {
  padding: 0px 0px 10px 0px !important;
}

/* Section Generate Result Datatable Vue */
.subTitle {
  text-align: center !important;
  background: white;
  // color: #1976D2 !important;
  display: none;
}

.form-datatable {
  padding: 10px 0px 0px 0px;
}

// .tableMaintenance > div:nth-child(1) > table > thead > tr > th{
//     background: #4495D1;
//     color: white !important;
//     font-weight: bold;
// }

.tableMaintenance > .v-data-table__wrapper {
  overflow-x: auto;
  overflow-y: auto;
  max-height: 550px;
}

/* Section Upload File */
.form-uploadFile {
  display: none;
}
</style>